<template>
  <div class="taskItem" :class="{ noMr: taskIndex == taskList.length - 1,  }" :data-item="taskInfo.taskInfoString">
    <el-popover placement="top" width="18.22917vw" trigger="manual" v-model="taskInfo.viewVisible" :ref="'taskPoper_'+taskInfo.type+'_'+taskInfo.id" :popper-options="{ boundariesElement: 'body', gpuAcceleration: false }" :visible-arrow="true" :key="'taskPoper_'+taskInfo.type+'_'+taskInfo.id">
      <div class="task-poper-body">
        <div class="poper-body-header">
          <img class="header-img" :src="taskInfo.iconName" alt="">
          <span>{{ taskInfo.itemName }}</span>
        </div>
        <div class="poper-body-content">
          <div class="item">任务开始时间：{{ taskInfo.startTime || '' }}</div>
          <div class="item">任务结束时间：{{ taskInfo.endTime || ''}}</div>
          <div class="item" v-if="taskInfo.taskStatus == '4'">任务完成时间：{{ taskInfo.doneTime || ''}}</div>
          <div class="item" v-if="taskInfo.taskStatus == '0' && !taskInfo.noHandle">距任务结束剩余：{{ taskInfo.surplusTime ? taskInfo.surplusTime+'天' : ''}}</div>
          <div class="item red" v-if="taskInfo.taskStatus == '1'">任务已逾期：{{ taskInfo.surplusTime ? taskInfo.surplusTime+'天' : ''}}</div>
        </div>
        <div class="poper-body-footer" v-if="taskInfo.taskButtonText">
          <el-button type="primary" @click.stop.prevent="handleTaskPoperFun" >{{ taskInfo.taskButtonText }}</el-button><!--:disabled="taskInfo.noHandle"-->
        </div>
      </div>
    </el-popover>
    <img class="task-img" :src="taskInfo.iconName" :data-item="taskInfo.taskInfoString" v-popover="'taskPoper_'+taskInfo.type+'_'+taskInfo.id" alt="" @click.stop.prevent="handleTaskImgShow">
  </div>
</template>
<script>
export default {
  name: 'showTaskCard',
  props: {
    taskList: {
      type: Array,
      default: []
    },
    taskInfo: {
      type: Object,
      default: null
    },
    taskIndex: {
      type: Number,
      default: null
    },
    infoIndex: {
      type: Number,
      default: null
    },
    listIndex: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    showType: {
      type: String,
      default: ''
    }
  },
  data(){
    return{

    }
  },
  methods: {
    handleTaskPoperFun(){
      this.$emit("handleTaskFun",this.taskInfo)
    },
    handleTaskImgShow(){
      this.$emit("showFun",{ type: this.type ,showType: this.showType, taskInfo: this.taskInfo ,taskIndex: this.taskIndex,i: this.infoIndex,index: this.listIndex })
    }
  }
}
</script>
<style lang="scss" scoped>
.taskItem{
  width: 20px;
  height: 20px;
  padding: 12px 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  &.noMr{
    margin-right: 5px;
  }
  &.weekPadding{
    padding: 0;
  }
  .task-img{
    width: 20px;
    height: 20px;
  }
}
.task-poper-body{
  width: 100%;
  .poper-body-header{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 14px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 9px;
      vertical-align: middle;
    }
    span{
      font-size: 16px;
      font-weight: 400;
      color: #3D3E4F;
      vertical-align: middle;
    }
  }
  .poper-body-content{
    width: 100%;
    .item{
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #9290B1;
      margin-bottom: 8px;
    }
  }
  .poper-body-footer{
    width: 100%;
    text-align: center;
    padding-top: 16px;
  }
}
</style>
