<template>
  <!-- 页面name：工作日历-index -->
  <div class="home-contain">
    <div class="header-title">首页</div>
    <div class="calendar-taskweek-info">
      <div class="calendar-info" ref="calendarInfo">
        <div class="calendar-body">
          <div class="calendar-body-header">
            <img
              class="icon-item"
              :src="calendarLeftArrowIcon"
              alt=""
              @click="handleChangeTime('prev')"
            />
            <div class="calendar-time" @click="handleShowDate">
              <span>{{ calendarYear }}年{{ calendarMonth }}月</span
              ><span v-show="calendarTab === '2'">第{{ calendarWeek }}周</span>
              <img
                :class="{ changeIcon: dateShow }"
                :src="calendarShowIcon"
                alt=""
              />
              <el-date-picker
                ref="datePicker"
                class="calendar-date-choose"
                v-model="dateTime"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="handleDateChange"
              ></el-date-picker>
            </div>
            <img
              class="icon-item"
              :src="calendarRightArrowIcon"
              alt=""
              @click="handleChangeTime('next')"
            />
          </div>

          <table
            class="calendar-body-info"
            v-show="calendarTab === '1'"
            ref="monthTr"
          >
            <thead>
              <tr>
                <th v-for="(item, index) in monthThList" :key="index">
                  <div>{{ item }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="month-tr"
                v-for="(item, index) in monthList"
                :key="index"
              >
                <td
                  class="month-td"
                  :class="{
                    noBorder: index == monthList.length - 1,
                    noRightBorder: i == item.length - 1,
                    active: info.isNow,
                    longHeight: monthList.length == 5,
                    disabledBg: info.isDisabled,
                  }"
                  v-for="(info, i) in item"
                  :key="info.day + '_' + i"
                  :style="{ width: monthTdWidth + 'px' }"
                >
                  <div class="day-show-info">
                    <div
                      class="day-show"
                      :class="{ active: info.isNow, gray: info.isDisabled }"
                    >
                      {{ info.day }}
                    </div>
                  </div>
                  <div class="task-list">
                    <!--@choose="onChoose"  @start="(e) => onStart(e,info,i,index)" @end="(e) => onEnd(e,info,i,index)" :move="(e) => checkMove(e,info,i,index)"-->
                    <div
                      class="task-all-list"
                      :style="{
                        width: monthTdWidth + 'px',
                        height: info.isShow ? 'auto' : '2.29167vw',
                        'overflow-y': info.isShow ? 'auto' : 'hidden',
                      }"
                    >
                      <draggable
                        class="task-all-list-inside"
                        v-model="info.taskList"
                        :animation="500"
                        :delay="200"
                        :force-fallback="true"
                        :element="'div'"
                        :handle="'.task-img'"
                        :dataIdAttr="'data-item'"
                        :filter="'.task-show-button,.el-popover'"
                        @choose="onChoose"
                        @start="(e) => onStart(e, info, i, index)"
                        @end="(e) => onEnd(e, info, i, index)"
                        :move="(e) => checkMove(e, info, i, index)"
                        :group="{
                          name: 'monthGrag',
                          pull: onMonthPull,
                          put: onMonthPut,
                        }"
                      >
                        <show-task-card
                          v-for="(taskInfo, taskIndex) in info.taskList"
                          :taskList="info.taskList"
                          :taskInfo="taskInfo"
                          :taskIndex="taskIndex"
                          :infoIndex="i"
                          :listIndex="index"
                          :key="taskInfo.type + '_' + taskInfo.id"
                          :type="'month'"
                          :showType="'list'"
                          @handleTaskFun="handleTaskPoperFun"
                          @showFun="handleTaskImgShow"
                        ></show-task-card>
                        <el-button
                          type="text"
                          class="task-show-button"
                          :class="{
                            moveShow: !info.isShow && !info.isNow,
                            moveBlueShow: !info.isShow && info.isNow,
                          }"
                          @click="handleShowAllTask('month', info, i, index)"
                          v-if="info.taskList.length > 3"
                          >{{ info.isShow ? "收起" : "展开" }}</el-button
                        >
                      </draggable>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="calendar-body-info"
            v-show="calendarTab === '2'"
            ref="weekTr"
          >
            <thead class="week">
              <tr>
                <th class="time"><div></div></th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 0 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    日
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 1 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    一
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 2 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    二
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 3 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    三
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 4 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    四
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 5 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    五
                  </div>
                </th>
                <th class="time" :style="{ width: weekTdWidth + 'px' }">
                  <div
                    :class="{
                      now:
                        calendarWeekDay == 6 && calendarWeekDay == weekShowDay,
                    }"
                  >
                    六
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="week-tr"
                v-for="(item, index) in weekList"
                :key="index"
              >
                <td
                  class="week-td"
                  :style="{ width: i !== 0 ? weekTdWidth + 'px' : '3.125vw' }"
                  :class="{
                    noBorder: index == weekList.length - 1 || i === 0,
                    noRightBorder: i == item.length - 1,
                    active: info.isNow,
                  }"
                  v-for="(info, i) in item"
                  :key="i === 0 ? info.time : info.day"
                >
                  <div class="time-start" v-if="i === 0">{{ info.time }}</div>
                  <div
                    class="time-end"
                    v-if="i === 0 && index === weekList.length - 1"
                  >
                    {{ info.timeEnd }}
                  </div>
                  <div class="task-list" v-if="i !== 0">
                    <!--@start="(e) => onWeekStart(e,info,i,index)" @end="(e) => onWeekEnd(e,info,i,index)" :move="(e) => checkWeekMove(e,info,i,index)"-->
                    <div
                      class="task-all-list"
                      :style="{
                        width: weekTdWidth + 'px',
                        height: info.isShow ? 'auto' : '2.29167vw',
                        'overflow-y': info.isShow ? 'auto' : 'hidden',
                      }"
                    >
                      <draggable
                        class="task-all-list-inside"
                        v-model="info.taskList"
                        :animation="500"
                        :delay="200"
                        :dataIdAttr="'data-item'"
                        :force-fallback="true"
                        :element="'div'"
                        :handle="'.task-img'"
                        :filter="'.el-popover,.task-show-button'"
                        @click="onClick"
                        @start="(e) => onWeekStart(e, info, i, index)"
                        @end="(e) => onWeekEnd(e, info, i, index)"
                        :move="(e) => checkWeekMove(e, info, i, index)"
                        :group="{ name: 'weekGrag', pull: true, put: true }"
                      >
                        <show-task-card
                          v-for="(taskInfo, taskIndex) in info.taskList"
                          :taskList="info.taskList"
                          :taskInfo="taskInfo"
                          :taskIndex="taskIndex"
                          :infoIndex="i"
                          :listIndex="index"
                          :key="taskInfo.type + '_' + taskInfo.id"
                          :type="'week'"
                          :showType="'list'"
                          @handleTaskFun="handleTaskPoperFun"
                          @showFun="handleTaskImgShow"
                        ></show-task-card>
                        <el-button
                          type="text"
                          class="task-show-button"
                          :class="{
                            moveShow: !info.isShow && !info.isNow,
                            moveBlueShow: !info.isShow && info.isNow,
                          }"
                          @click="handleShowAllTask('week', info, i, index)"
                          v-if="info.taskList.length > 3"
                          >{{ info.isShow ? "收起" : "展开" }}</el-button
                        >
                      </draggable>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="calendar-tabs">
          <div
            class="tab-item"
            :class="{ active: calendarTab == '1' }"
            @click="handleCalendarTabChange('1')"
          >
            月
          </div>
          <div
            class="tab-item"
            :class="{ active: calendarTab == '2' }"
            @click="handleCalendarTabChange('2')"
          >
            周
          </div>
        </div>
      </div>
      <div class="taskweek-info" :style="{ height: calendarHeight + 'px' }">
        <div
          class="warning-tip"
          :class="{ mt: isHaveTaskLastMonth }"
          v-if="isHaveTaskLastMonth"
        >
          <img :src="taskWarningIcon" alt="" /><span>您上月仍有待办未完成</span>
        </div>
        <div class="taskweek-header" :class="{ mt: !isHaveTaskLastMonth }">
          <div class="task-title">本周任务</div>
          <div class="taskweek-text">
            {{ lastYear + "~" + nowYear }}学年 {{ nowMonth }}月第{{ nowWeek }}周
          </div>
        </div>
        <div
          class="taskweek-list-outside"
          :class="{ haveTip: isHaveTaskLastMonth }"
        >
          <div class="taskweek-list" v-if="taskWeekList.length > 0">
            <div
              class="list-item"
              v-for="item in taskWeekList"
              :key="item.type + '_' + item.id"
            >
              <div class="item-info">
                <div class="icon" :class="{ red: item.isDelay }">
                  <img :src="item.iconName" alt="" />
                </div>
                <div class="text" :class="{ gray: item.isDelay }">
                  {{ item.itemName }}
                </div>
              </div>
              <el-button
                @click="handleTaskToDoFun(item)"
                :disabled="item.type == '2' && (item.isDelay || item.noHandle)"
                type="text"
                >办理</el-button
              >
            </div>
          </div>
          <div class="taskweek-list-empty" v-if="taskWeekList.length == 0">
            <img :src="taskEmptyBg" alt="" />
            <div class="taskweek-list-empty-text">本周暂无待办事项</div>
          </div>
        </div>
        <el-button
          type="primary"
          class="taskweek-footer-btn"
          @click="addOperateTask"
          >新增</el-button
        >
      </div>
    </div>
    <div class="all-task-info">
      <div class="task-header">
        <div class="task-title">待办任务</div>
        <el-button
          v-if="taskTab == '1'"
          type="primary"
          class="sheet-btn"
          @click="batchTask"
          >批量办理</el-button
        >
      </div>
      <div class="task-tabs">
        <div
          class="tab-item"
          :ref="'tab_1'"
          :class="{ active: taskTab == '1' }"
          @click="handleTaskTabChange('1')"
        >
          运营任务
        </div>
        <div
          class="tab-item"
          :ref="'tab_2'"
          :class="{ active: taskTab == '2' }"
          @click="handleTaskTabChange('2')"
        >
          督导任务
        </div>
        <div
          class="tab-item"
          :ref="'tab_3'"
          :class="{ active: taskTab == '3' }"
          @click="handleTaskTabChange('3')"
        >
          目标任务
        </div>
        <div
          class="tab-line"
          :style="{ transform: 'translateX(' + lineLeft + 'px)' }"
        ></div>
      </div>
      <div class="all-task-list-outside" ref="taskListOutside">
        <div
          class="all-task-list"
          :style="{
            width: !competedListAllWidth ? '' : competedListAllWidth,
            transform: 'translateX(' + tableLeft + 'px)',
          }"
        >
          <div
            class="item-list"
            :style="{ width: !competedListWidth ? 'unset' : competedListWidth }"
          >
            <el-form
              :model="operateTaskForm"
              onsubmit="return false"
              :inline="true"
            >
              <el-form-item>
                <el-input
                  v-model="operateTaskForm.itemName"
                  placeholder="请输入任务名称"
                />
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="operateDeadLineTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="截止开始时间"
                  end-placeholder="截止结束时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="handleOperateDeadLineTime"
                ></el-date-picker>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="operateSearchFun"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh-right" @click="operateResetFun"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
            <l-table
              :tableRef="'operateTable'"
              :columns="operateColumns"
              :dataSource="operateTableData"
              :showCheckBox="true"
              :isSelectable="true"
              :pagination="operatePageFlag"
              :ipagination="operatePagination"
              @current-change="handleOperateCurrentChange"
              @size-change="handleOperateSizeChange"
              @selection-change="handleOperateSelectionChange"
              :selectable="handelOperateSelectable"
              :loading="operateTableLoading"
              :showXh="true"
              :height="'calc(100% - 2.91667vw)'"
              :autoHeight="false"
            >
              <el-table-column
                prop="itemName"
                label="任务名称"
                align="center"
                slot="itemName"
              ></el-table-column>
              <el-table-column
                prop="startTime"
                label="开始时间"
                align="center"
                slot="startTime"
              ></el-table-column>
              <el-table-column
                prop="endTime"
                label="截止时间"
                align="center"
                slot="endTime"
              ></el-table-column>
              <el-table-column
                prop="number"
                label="次数/总次数"
                align="center"
                slot="number"
                width="120"
              >
                <template slot-scope="scope">
                  <span>{{
                    (scope.row.presentTimes || 0) +
                    "/" +
                    (scope.row.itemTimes || 0)
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="period"
                label="周期(天)"
                width="100"
                align="center"
                slot="period"
              ></el-table-column>
              <el-table-column
                prop="overdue"
                label="逾期时间(天)"
                width="100"
                align="center"
                slot="overdue"
              ></el-table-column>
              <el-table-column
                prop="taskStatus"
                label="状态"
                align="center"
                width="80"
                slot="taskStatus"
              >
                <template slot-scope="scope">
                  <span :class="{ delay: scope.row.taskStatus.value == '1' }">{{
                    scope.row.taskStatus.label
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                slot="handle"
                align="center"
                width="200"
              >
                <template slot-scope="scope">
                  <el-button
                    class="table-handle"
                    @click="handleOperateTaskFun('view', scope.row)"
                    type="text"
                    >查看</el-button
                  >
                  <el-button
                    class="table-handle"
                    @click="handleOperateTaskFun('handle', scope.row)"
                    type="text"
                    v-if="
                      (scope.row.taskStatus.value === '0' &&
                        scope.row.timeBtn >= 0 &&
                        scope.row.isCustom &&
                        scope.row.isCustom.value === '0') ||
                      (scope.row.isCustom &&
                        scope.row.isCustom.value === '1' &&
                        scope.row.timeBtn >= 0 &&
                        scope.row.taskStatus.value !== '4')
                    "
                    >办理</el-button
                  >
                  <el-button
                    class="table-handle"
                    @click="handleOperateTaskFun('overdueFeedback', scope.row)"
                    type="text"
                    v-if="
                      scope.row.taskStatus.value === '1' &&
                      scope.row.isCustom &&
                      scope.row.isCustom.value === '0'
                    "
                    >逾期反馈</el-button
                  >
                  <el-button
                    class="table-handle"
                    @click="handleOperateTaskFun('approve', scope.row)"
                    type="text"
                    v-if="
                      scope.row.taskStatus.value === '2' &&
                      scope.row.isCustom &&
                      scope.row.isCustom.value === '0'
                    "
                    >审批</el-button
                  >
                </template>
              </el-table-column>
            </l-table>
          </div>
          <div
            class="item-list"
            :style="{ width: !competedListWidth ? 'unset' : competedListWidth }"
          >
            <el-form
              :model="superviseTaskForm"
              onsubmit="return false"
              :inline="true"
            >
              <el-form-item>
                <el-input
                  v-model="superviseTaskForm.supervisionTopic"
                  placeholder="请输入督导主题"
                />
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="superviseDeadLineTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="截止开始时间"
                  end-placeholder="截止结束时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="handleSuperviseDeadLineTime"
                ></el-date-picker>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="superviseSearchFun"
                  >查询</el-button
                >
                <el-button
                  icon="el-icon-refresh-right"
                  @click="superviseResetFun"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
            <l-table
              :tableRef="'superviseTable'"
              :columns="superviseColumns"
              :dataSource="superviseTableData"
              :pagination="supervisePageFlag"
              :ipagination="supervisePagination"
              @current-change="handleSuperviseCurrentChange"
              @size-change="handleSuperviseSizeChange"
              :loading="superviseTableLoading"
              :showXh="true"
              :height="'calc(100% - 2.91667vw)'"
              :autoHeight="false"
            >
              <el-table-column
                prop="jobName"
                label="岗位"
                align="center"
                slot="jobName"
              ></el-table-column>
              <el-table-column
                prop="supervisionTopic"
                label="督导主题"
                align="center"
                slot="supervisionTopic"
              ></el-table-column>
              <el-table-column
                prop="supervisionMethodName"
                label="督导方式"
                align="center"
                slot="supervisionMethodName"
              ></el-table-column>
              <el-table-column
                prop="totalQuestions"
                label="总题数"
                align="center"
                slot="totalQuestions"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="totalScore"
                label="总分数"
                width="100"
                align="center"
                slot="totalScore"
              ></el-table-column>
              <el-table-column
                prop="sendTime"
                label="发布时间"
                width="150"
                align="center"
                slot="sendTime"
              ></el-table-column>
              <el-table-column
                prop="parkDeadline"
                label="截止日期"
                width="100"
                align="center"
                slot="parkDeadline"
              ></el-table-column>
              <el-table-column
                prop="statusName"
                label="流程状态"
                align="center"
                width="120"
                slot="statusName"
              >
                <template slot-scope="scope">
                  <span
                    :class="{
                      delay:
                        scope.row.status == '4' ||
                        scope.row.status == '5' ||
                        scope.row.status == '6' ||
                        scope.row.status == '7',
                    }"
                    >{{ scope.row.statusName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                slot="handle"
                align="center"
                width="250"
              >
                <template slot-scope="scope">
                  <el-button
                    class="table-handle"
                    @click="handleSuperviseTaskFun('view', scope.row)"
                    type="text"
                    >查看</el-button
                  >
                  <el-button
                    class="table-handle"
                    @click="handleSuperviseTaskFun('score', scope.row)"
                    type="text"
                    >评分</el-button
                  >
                  <!-- <el-button class="table-handle" @click="handleSuperviseTaskFun('supervise',scope.row)" type="text">督办</el-button> -->
                </template>
              </el-table-column>
            </l-table>
          </div>
          <div
            class="item-list"
            :style="{ width: !competedListWidth ? 'unset' : competedListWidth }"
          >
            <el-form
              :model="targetTaskForm"
              onsubmit="return false"
              :inline="true"
            >
              <el-form-item>
                <el-input
                  v-model="targetTaskForm.targetName"
                  placeholder="请输入目标名称"
                />
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="targetDeadLineTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="截止开始时间"
                  end-placeholder="截止结束时间"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="handleTargetDeadLineTime"
                ></el-date-picker>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="targetSearchFun"
                  >查询</el-button
                >
                <el-button icon="el-icon-refresh-right" @click="targetResetFun"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
            <l-table
              :tableRef="'targetTable'"
              :columns="targetColumns"
              :dataSource="targetTableData"
              :pagination="targetPageFlag"
              :ipagination="targetPagination"
              @current-change="handleTargetCurrentChange"
              @size-change="handleTargetSizeChange"
              :loading="targetTableLoading"
              :showXh="true"
              :height="'calc(100% - 2.91667vw)'"
              :autoHeight="false"
            >
              <el-table-column
                prop="targetName"
                label="目标名称"
                width="200"
                align="center"
                slot="targetName"
              ></el-table-column>
              <el-table-column
                prop="targetType"
                label="目标类型"
                width="180"
                align="center"
                slot="targetType"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.targetType.label }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="targetValue"
                label="目标值"
                align="center"
                slot="targetValue"
              ></el-table-column>
              <el-table-column
                prop="doneValue"
                label="已完成"
                align="center"
                slot="doneValue"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="targetUnit"
                label="计量单位"
                width="100"
                align="center"
                slot="targetUnit"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="创建时间"
                width="150"
                align="center"
                slot="createTime"
              ></el-table-column>
              <el-table-column
                prop="endTime"
                label="截止时间"
                width="150"
                align="center"
                slot="endTime"
              ></el-table-column>
              <el-table-column
                prop="donePer"
                label="完成进度"
                width="120"
                align="center"
                slot="donePer"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.donePer
                      ? (Number(scope.row.donePer) * 100).toFixed(2) + "%"
                      : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="targetDescript"
                label="描述"
                width="200"
                align="center"
                slot="targetDescript"
              >
                <template slot-scope="scope">
                  <el-tooltip
                    class="item"
                    effect="light"
                    popper-class="customPopper"
                    :content="scope.row.targetDescript"
                    placement="top"
                    v-if="scope.row.targetDescript"
                  >
                    <div class="description-div">
                      {{ scope.row.targetDescript }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="目标状态"
                align="center"
                width="90"
                slot="status"
              >
                <template slot-scope="scope">
                  <span :class="{ delay: scope.row.status.value == '2' }">{{
                    scope.row.status.label
                  }}</span
                  ><!--, finish: scope.row.status.value == '3'-->
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                slot="handle"
                align="center"
                width="120"
              >
                <template slot-scope="scope">
                  <el-button
                    class="table-handle"
                    v-if="
                      scope.row.status.value == 1 &&
                      (scope.row.targetType.value == 4 ||
                        scope.row.targetType.value == 5)
                    "
                    @click="
                      handleTargetTaskFun(
                        'updateDonePer',
                        scope.row,
                        scope.row.targetType.value
                      )
                    "
                    type="text"
                    >更新进度</el-button
                  >
                  <el-button
                    class="table-handle"
                    v-if="
                      scope.row.status.value == 1 &&
                      scope.row.targetType.value != 4 &&
                      scope.row.targetType.value != 5 &&
                      scope.row.updateType == 0
                    "
                    @click="handleTargetTaskFun('updateDonePer', scope.row, 0)"
                    type="text"
                    >更新进度</el-button
                  >
                </template>
              </el-table-column>
            </l-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import taskWarningIcon from "@/assets/img/task-warning-icon.png";
import calendarShowIcon from "@/assets/img/calendar-show-icon.png";
import calendarLeftArrowIcon from "@/assets/img/calendar-left-arrow-icon.png";
import calendarRightArrowIcon from "@/assets/img/calendar-right-arrow-icon.png";
import taskEmptyBg from "@/assets/img/task-empty-bg.png";
import { mapGetters, mapState } from "vuex";
import LTable from "@/components/tableComponent/index.vue";
import tool from "@/utils/tool.js";
import ShowTaskCard from "./components/showTaskCard.vue";
export default {
  components: { LTable, draggable, ShowTaskCard },
  data() {
    return {
      taskTab: "1", //任务tab
      lineLeft: 18, //tab下划线移动距离

      calendarTab: "1", //日历任务tab
      calendarYear: "", //日历年
      calendarMonth: "", //日历月
      calendarWeek: "", //日历第几周
      calendarWeekDay: "", //日历周几
      dateShow: false, //日历日期展示
      dateTime: "", //日期选中值
      weekShowDay: "", //日历周当天

      monthList: [], // 日历月待办list
      monthThList: ["日", "一", "二", "三", "四", "五", "六"], //日历表头
      calendarMoveList: [], //日历移动list
      monthTdWidth: "", //日历月计算宽度
      weekList: [], //日历周待办list
      calendarTimeMoveList: [], //日历周时间移动list
      weekTdWidth: "", //日历周计算的宽度

      isHaveTaskLastMonth: false, //是否有上月逾期
      lastYear: "", //上一年
      nowYear: "", //当前年
      nowMonth: "", //当前月
      nowWeek: "", //当前周

      taskWeekList: [], //本周待办list

      tableLeft: 0, //表格移动距离
      operateTaskForm: {
        itemName: "",
        endTimeBegin: "",
        endTimeEnd: "",
      }, //运营待办查询条件form
      operateDeadLineTime: [], //运营待办查询条件的时间段
      operateColumns: [
        {
          label: "任务名称",
          slotName: "itemName",
        },
        {
          label: "开始时间",
          slotName: "startTime",
        },
        {
          label: "截止时间",
          slotName: "endTime",
        },
        {
          label: "次数/总次数",
          slotName: "number",
        },
        {
          label: "周期(天)",
          slotName: "period",
        },
        {
          label: "逾期时间(天)",
          slotName: "overdue",
        },
        {
          label: "状态",
          slotName: "taskStatus",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ], //运营待办任务列
      operateTableData: [], //运营待办数据
      operateTableSelectData: [], //运营待办选中数据
      operatePageFlag: false, //运营待办是否显示分页
      operatePagination: {
        total: 0, // 总页数
        num: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      operateTableLoading: false, // 运营表格loading

      superviseTaskForm: {
        supervisionTopic: "",
        beginTime: "",
        endTime: "",
      }, //督导待办查询条件form
      superviseDeadLineTime: [], //督导待办查询条件的时间段
      superviseColumns: [
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "督导主题",
          slotName: "supervisionTopic",
        },
        {
          label: "督导方式",
          slotName: "supervisionMethodName",
        },
        {
          label: "总题数",
          slotName: "totalQuestions",
        },
        {
          label: "总分数",
          slotName: "totalScore",
        },
        {
          label: "发布时间",
          slotName: "sendTime",
        },
        {
          label: "截止日期",
          slotName: "parkDeadline",
        },
        {
          label: "状态",
          slotName: "statusName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ], //督导待办任务列
      superviseTableData: [], //督导待办数据
      supervisePageFlag: false, //督导待办是否显示分页
      supervisePagination: {
        total: 0, // 总页数
        num: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      superviseTableLoading: false, // 督导表格loading
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      flowStatusOptions: [
        {
          name: "园校待办",
          value: "1",
        },
        {
          name: "分公司待办",
          value: "2",
        },
        {
          name: "总部待办",
          value: "3",
        },
        {
          name: "分公司驳回",
          value: "4",
        },
        {
          name: "总部驳回",
          value: "5",
        },
        {
          name: "园校逾期待办",
          value: "6",
        },
        {
          name: "分公司逾期待办",
          value: "7",
        },
        {
          name: "总部未评分",
          value: "8",
        },
        {
          name: "已完成",
          value: "9",
        },
      ], //状态下拉

      targetTaskForm: {
        targetName: "",
        searchStartTime: "",
        searchEndTime: "",
      }, //目标待办查询条件form
      targetDeadLineTime: [], //目标待办查询条件的时间段
      targetColumns: [
        {
          label: "目标名称",
          slotName: "targetName",
        },
        {
          label: "目标类型",
          slotName: "targetType",
        },
        {
          label: "目标值",
          slotName: "targetValue",
        },
        {
          label: "已完成",
          slotName: "doneValue",
        },
        {
          label: "计量单位",
          slotName: "targetUnit",
        },
        {
          label: "创建时间",
          slotName: "createTime",
        },
        {
          label: "截止时间",
          slotName: "endTime",
        },
        {
          label: "完成进度",
          slotName: "donePer",
        },
        {
          label: "描述",
          slotName: "targetDescript",
        },
        {
          label: "目标状态",
          slotName: "status",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ], //目标待办任务列
      targetTableData: [], //目标待办数据
      targetPageFlag: false, //目标待办是否显示分页
      targetPagination: {
        total: 0, // 总页数
        num: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      targetTableLoading: false, // 督导表格loading

      listWidth: 0, //表格宽度
      calendarAllHeight: "", //总高度

      drag: false, //日历月是否可以拖拽
      weekGrag: false, //日历周是否可以拖拽

      userInfo: {}, //登录用户信息

      taskWarningIcon, //任务警告icon
      calendarShowIcon, //日历展示icon
      calendarLeftArrowIcon, //日历左箭头icon
      calendarRightArrowIcon, //日历右箭头icon
      taskEmptyBg, //周任务为空的图片
    };
  },
  watch: {
    taskTab(val) {
      if (val == "1") {
        this.lineLeft =
          this.$refs["tab_1"].clientWidth / 2 - (10 / 1920) * window.outerWidth;
        this.tableLeft = 0;
      } else if (val == "2") {
        this.lineLeft =
          this.$refs["tab_1"].clientWidth +
          (34 / 1920) * window.outerWidth +
          (this.$refs["tab_2"].clientWidth / 2 -
            (10 / 1920) * window.outerWidth);
        this.tableLeft = -this.listWidth;
      } else if (val == "3") {
        this.lineLeft =
          this.$refs["tab_1"].clientWidth +
          (34 / 1920) * window.outerWidth +
          this.$refs["tab_2"].clientWidth +
          (34 / 1920) * window.outerWidth +
          (this.$refs["tab_3"].clientWidth / 2 -
            (10 / 1920) * window.outerWidth);
        this.tableLeft = -this.listWidth * 2;
      }
    },
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;

    let date = new Date();
    if (date.getMonth() < 8) {
      this.lastYear = date.getFullYear() - 1;
      this.nowYear = date.getFullYear();
    } else {
      this.lastYear = date.getFullYear();
      this.nowYear = date.getFullYear() + 1;
    }
    this.nowMonth = date.getMonth() + 1;
    this.nowWeek = this.getMonthWeek(
      this.nowYear,
      this.nowMonth,
      date.getDate()
    );

    this.dateTime = tool.formatTime(date);
    this.calendarYear = date.getFullYear();
    this.calendarMonth = date.getMonth() + 1;
    this.calendarWeek = this.getMonthWeek(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    this.calendarWeekDay = date.getDay();
    this.getAllMonthDay();
    this.getCalendarTask("nowWeek");
    if (date.getDate() == 1) {
      this.getCalendarTask("lastMonth");
    }
    this.operateTableLoading = true;
    this.getOperateTaskList();
    setTimeout(() => {
      this.listWidth = this.$refs.taskListOutside.clientWidth;
    }, 100);
  },
  mounted() {
    let that = this;
    document.addEventListener("click", function (e) {
      console.log(e);
      that.monthList.map((item, index) => {
        item.map((info, i) => {
          if (info.taskList && info.taskList.length > 0) {
            info.taskList.map((taskInfo) => {
              taskInfo.viewVisible = false;
            });
          }
        });
      });
      that.weekList.map((item, index) => {
        item.map((info, i) => {
          if (info.taskList && info.taskList.length > 0) {
            info.taskList.map((taskInfo) => {
              taskInfo.viewVisible = false;
            });
          }
        });
      });
    });
  },
  computed: {
    ...mapState({
      targetTypeList: (state) => state.common.targetTypeList,
      targetStatusList: (state) => state.common.targetStatusList,
    }),
    competedListWidth() {
      return this.listWidth + "px";
    },
    competedListAllWidth() {
      return this.listWidth * 3 + "px";
    },
    calendarHeight() {
      return this.calendarAllHeight ? this.calendarAllHeight : "";
    },
  },
  methods: {
    //改变日历周月
    handleCalendarTabChange(type) {
      this.calendarTab = type;
      if (type == "2") {
        this.getDayTime();
      } else if (type == "1") {
        this.getAllMonthDay();
      }
    },
    //展示要选择的日期
    handleShowDate() {
      if (this.dateShow) {
        this.$refs.datePicker.pickerVisible = false;
      } else {
        this.$refs.datePicker.pickerVisible = true;
      }
      this.dateShow = !this.dateShow;
    },
    //改变时间
    handleChangeTime(type) {
      let year = "";
      let month = "";
      if (this.calendarTab === "1") {
        let date = new Date(this.dateTime);
        let weekDay = date.getDay();
        if (type == "prev") {
          if (this.calendarMonth == "1") {
            year = Number(this.calendarYear) - 1;
            month = 12;
          } else {
            year = this.calendarYear;
            month = this.calendarMonth - 1;
          }
        } else if (type == "next") {
          if (this.calendarMonth == "12") {
            year = Number(this.calendarYear) + 1;
            month = 1;
          } else {
            year = this.calendarYear;
            month = this.calendarMonth + 1;
          }
        }
        console.log(year);
        console.log(month);
        console.log(this.calendarWeek);
        this.calendarWeek = 1;
        this.dateTime = this.getWeekTime(
          year,
          month,
          this.calendarWeek,
          weekDay
        );
        console.log(this.dateTime);
        this.calendarYear = year;
        this.calendarMonth = month;
        this.getAllMonthDay();
      } else if (this.calendarTab === "2") {
        let last = null;
        let first = null;
        let maxWeek = 0;
        let week = 0;
        if (type == "prev") {
          if (this.calendarWeek == "1" || this.calendarWeek == "2") {
            if (this.calendarMonth == "1") {
              year = Number(this.calendarYear) - 1;
              month = 12;
            } else {
              year = this.calendarYear;
              month = this.calendarMonth - 1;
            }
            last = new Date(year, month, 0);
            maxWeek = this.getMonthWeek(
              last.getFullYear(),
              last.getMonth() + 1,
              last.getDate()
            );
            if (
              this.calendarWeek == "1" &&
              this.calendarWeekDay > 0 &&
              last.getDay() < 6
            ) {
              week = maxWeek - 1;
            } else if (this.calendarWeek == "2") {
              if (this.calendarWeekDay <= last.getDay() && last.getDay() < 6) {
                week = maxWeek - 1;
              } else {
                year = this.calendarYear;
                month = this.calendarMonth;
                last = new Date(year, month, 0);
                maxWeek = this.getMonthWeek(
                  last.getFullYear(),
                  last.getMonth() + 1,
                  last.getDate()
                );
                week = 1;
              }
            }
          } else {
            year = this.calendarYear;
            month = this.calendarMonth;
            last = new Date(year, month, 0);
            maxWeek = this.getMonthWeek(
              last.getFullYear(),
              last.getMonth() + 1,
              last.getDate()
            );
            week = this.calendarWeek - 1;
          }
        } else if (type == "next") {
          year = this.calendarYear;
          month = this.calendarMonth;
          last = new Date(year, month, 0);
          maxWeek = this.getMonthWeek(
            last.getFullYear(),
            last.getMonth() + 1,
            last.getDate()
          );
          if (
            this.calendarWeek == maxWeek ||
            this.calendarWeek == maxWeek - 1
          ) {
            if (this.calendarMonth == "12") {
              year = Number(this.calendarYear) + 1;
              month = 1;
            } else {
              year = this.calendarYear;
              month = this.calendarMonth + 1;
            }
            first = new Date(year, month, 1);
            if (
              (this.calendarWeekDay < first.getDay() &&
                this.calendarWeek == maxWeek - 1 &&
                last.getDay() < 6) ||
              (this.calendarWeek == maxWeek - 1 && last.getDay() == 6)
            ) {
              year = this.calendarYear;
              month = this.calendarMonth;
              week = this.calendarWeek + 1;
            } else if (
              (this.calendarWeekDay >= first.getDay() &&
                this.calendarWeek == maxWeek - 1 &&
                last.getDay() < 6) ||
              (this.calendarWeek == maxWeek && last.getDay() == 6)
            ) {
              week = 1;
            } else if (this.calendarWeek == maxWeek && last.getDay() < 6) {
              week = 2;
            }
          } else {
            week = this.calendarWeek + 1;
          }
        }
        console.log(year);
        console.log(month);
        this.dateTime = this.getWeekTime(
          year,
          month,
          week,
          this.calendarWeekDay
        );
        console.log(this.dateTime);
        this.calendarYear = year;
        this.calendarMonth = month;
        this.calendarWeek = week;
        this.getAllMonthDay();
        setTimeout(() => {
          this.getDayTime();
        }, 500);
      }
    },
    //改变任务tabs
    handleTaskTabChange(type) {
      this.taskTab = type;
      if (type === "1") {
        if (this.operateTableData.length === 0) {
          this.operateTableLoading = true;
          this.getOperateTaskList();
        }
      } else if (type === "2") {
        if (this.superviseTableData.length === 0) {
          this.superviseTableLoading = true;
          this.getSuperviseTaskList();
        }
      } else if (type === "3") {
        if (this.targetTableData.length === 0) {
          this.targetTableLoading = true;
          this.getTargetTaskList();
        }
      }
    },
    //改变日期
    handleDateChange(val) {
      let date = new Date(val);
      let weekDay = date.getDay();
      let week = this.getMonthWeek(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      this.dateTime = val;
      this.calendarYear = year;
      this.calendarMonth = month;
      this.calendarWeek = week;
      this.calendarWeekDay = weekDay;
      this.dateShow = false;
      if (this.calendarTab === "1") {
        this.getAllMonthDay();
      } else if (this.calendarTab === "2") {
        this.getCalendarTask("week");
      }
    },
    //检查移动
    checkMove(evt, info, i, index) {
      return true;
    },
    //鼠标选择事件
    onChoose(e) {
      console.log(e);
      let moveitemInfo = JSON.parse(e.item.dataset.item);
    },
    //改变事件
    onChange(e, info, i, index) {
      console.log(e);
    },
    //开始拖拽事件
    onStart(e, info, i, index) {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd(e, info, i, index) {
      let nowDate = new Date();
      if (nowDate.getHours() > 18) {
        nowDate.setDate(nowDate.getDate() + 1);
        nowDate.setHours(8);
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      } else if (nowDate.getHours() < 8) {
        nowDate.setHours(8);
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      } else {
        nowDate.setHours(8);
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      }
      let showIndex = -1;
      let moveMonthIndex = -1;
      let moveWeekIndex = -1;
      let moveTaskIndex = -1;
      let isMoveable = false;
      let params = {};
      let moveitemInfo = JSON.parse(e.item.dataset.item);
      if (moveitemInfo.taskStatus == "1") {
        this.$message.error("任务已经逾期，不可更改");
        this.getAllMonthDay();
      } else if (moveitemInfo.taskStatus == "4") {
        this.$message.error("任务已经完成，不可更改");
        this.getAllMonthDay();
      } else {
        showIndex = this.monthList[index][i].taskShowList.findIndex(
          (showInfo) => {
            return showInfo.id == moveitemInfo.id;
          }
        );
        this.monthList.map((item, monthItemIndex) => {
          item.map((itemInfo, itemIndex) => {
            let taskIndex = itemInfo.taskList.findIndex((taskInfo) => {
              return taskInfo.id == moveitemInfo.id;
            });
            if (taskIndex != -1) {
              let startTime =
                nowDate.getTime() < new Date(moveitemInfo.startTime).getTime()
                  ? new Date(moveitemInfo.startTime)
                  : nowDate;
              if (
                startTime.getTime() <=
                  new Date(
                    itemInfo.date +
                      " " +
                      (startTime.getHours() < 9
                        ? "0" + startTime.getHours()
                        : startTime.getHours()) +
                      ":00:00"
                  ).getTime() &&
                new Date(moveitemInfo.endTime).getTime() >
                  new Date(itemInfo.date + " 18:00:00").getTime()
              ) {
                moveMonthIndex = monthItemIndex;
                moveWeekIndex = itemIndex;
                moveTaskIndex = taskIndex;
                isMoveable = true;
              }
            }
          });
        });
        if (isMoveable) {
          if (showIndex != -1) {
            this.monthList[index][i].taskShowList.splice(showIndex, 1);
          }
          if (moveitemInfo.type == "1") {
            params.orderId = moveitemInfo.id;
            params.time =
              this.monthList[moveMonthIndex][moveWeekIndex].date + " 08:00:00";
            this.$api
              .updateSuperviseTaskTime(params)
              .then((res) => {
                if (res.data.code === "0") {
                  this.getAllMonthDay();
                } else {
                  this.$message.error(res.data.msg);
                  this.getAllMonthDay();
                }
              })
              .catch(() => {
                this.getAllMonthDay();
              });
          } else if (moveitemInfo.type == "2") {
            params.id = moveitemInfo.id;
            params.realStartTime =
              this.monthList[moveMonthIndex][moveWeekIndex].date + " 08:00:00";
            this.$api
              .updateOperationTaskTime(params)
              .then((res) => {
                if (res.data.code === "0") {
                  this.getAllMonthDay();
                } else {
                  this.$message.error(res.data.msg);
                  this.getAllMonthDay();
                }
              })
              .catch(() => {
                this.getAllMonthDay();
              });
          }
        } else {
          this.$message.error("已超过可移动范围，请重新移动");
          this.getAllMonthDay();
        }
      }

      this.drag = false;
    },
    //月日历的拖出事件
    onMonthPull() {
      let flag = true;
      return flag;
    },
    //月日历的拖入事件
    onMonthPut() {
      let flag = true;
      return flag;
    },
    //日历周检查移动
    checkWeekMove(evt, info, i, index) {
      return true;
    },
    //触发click事件
    onClick(e) {
      console.log(e);
    },
    //周变化事件
    onWeekChange(e, info, i, index) {
      console.log(e);
    },
    //日历周开始拖拽事件
    onWeekStart(e, info, i, index) {
      let resultInfo = JSON.parse(JSON.stringify(info));
      resultInfo.taskList.map((item) => {
        item.viewVisible = false;
      });
      this.$set(this.weekList[index], i, resultInfo);
      this.weekDrag = true;
    },
    //日历周拖拽结束事件
    onWeekEnd(e, info, i, index) {
      let nowDate = new Date();
      if (nowDate.getHours() > 18) {
        nowDate.setDate(nowDate.getDate() + 1);
        nowDate.setHours(8);
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      } else if (nowDate.getHours() < 8) {
        nowDate.setHours(8);
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      } else {
        nowDate.setMinutes(0);
        nowDate.setSeconds(0);
        nowDate.setMilliseconds(0);
      }
      let showIndex = -1;
      let moveWeekIndex = -1;
      let moveWeekDayIndex = -1;
      let moveTaskIndex = -1;
      let isMoveable = false;
      let params = {};
      let moveitemInfo = JSON.parse(e.item.dataset.item);
      if (moveitemInfo.taskStatus == "1") {
        this.$message.error("任务已经逾期，不可更改");
        this.getDayTime();
      } else if (moveitemInfo.taskStatus == "4") {
        this.$message.error("任务已经完成，不可更改");
        this.getDayTime();
      } else {
        showIndex = this.weekList[index][i].taskShowList.findIndex(
          (showInfo) => {
            return showInfo.id == moveitemInfo.id;
          }
        );
        this.weekList.map((item, weekItemIndex) => {
          item.map((itemInfo, itemIndex) => {
            if (itemIndex !== 0) {
              let taskIndex = itemInfo.taskList.findIndex((taskInfo) => {
                return taskInfo.id == moveitemInfo.id;
              });
              if (taskIndex != -1) {
                let startTime =
                  nowDate.getTime() < new Date(moveitemInfo.startTime).getTime()
                    ? new Date(moveitemInfo.startTime).getTime()
                    : nowDate.getTime();
                if (
                  startTime <=
                    new Date(
                      itemInfo.date + " " + itemInfo.timeStart + ":00"
                    ).getTime() &&
                  new Date(moveitemInfo.endTime).getTime() >
                    new Date(
                      itemInfo.date + " " + itemInfo.timeEnd + ":00"
                    ).getTime()
                ) {
                  moveWeekIndex = weekItemIndex;
                  moveWeekDayIndex = itemIndex;
                  moveTaskIndex = taskIndex;
                  isMoveable = true;
                }
              }
            }
          });
        });
        if (isMoveable) {
          if (showIndex != -1) {
            this.weekList[index][i].taskShowList.splice(showIndex, 1);
          }
          if (moveitemInfo.type == "1") {
            params.orderId = moveitemInfo.id;
            params.time =
              this.weekList[moveWeekIndex][moveWeekDayIndex].date +
              " " +
              this.weekList[moveWeekIndex][moveWeekDayIndex].timeStart +
              ":00";
            this.$api
              .updateSuperviseTaskTime(params)
              .then((res) => {
                if (res.data.code === "0") {
                  this.getDayTime();
                } else {
                  this.$message.error(res.data.msg);
                  this.getDayTime();
                }
              })
              .catch(() => {
                this.getDayTime();
              });
          } else if (moveitemInfo.type == "2") {
            params.id = moveitemInfo.id;
            params.realStartTime =
              this.weekList[moveWeekIndex][moveWeekDayIndex].date +
              " " +
              this.weekList[moveWeekIndex][moveWeekDayIndex].timeStart +
              ":00";
            this.$api
              .updateOperationTaskTime(params)
              .then((res) => {
                if (res.data.code === "0") {
                  this.getDayTime();
                } else {
                  this.$message.error(res.data.msg);
                  this.getDayTime();
                }
              })
              .catch(() => {
                this.getDayTime();
              });
          }
        } else {
          this.$message.error("已超过可移动范围，请重新移动");
          this.getDayTime();
        }
      }
      this.weekGrag = false;
    },
    //处理任务信息查看
    handleTaskImgShow(options) {
      let { type, showType, taskInfo, taskIndex, i, index } = options;
      let info = JSON.parse(JSON.stringify(taskInfo));
      let infoList = [];
      if (type === "week") {
        if (showType == "show") {
          infoList = JSON.parse(
            JSON.stringify(this.weekList[index][i].taskShowList)
          );
          infoList.map((item, itemIndex) => {
            if (itemIndex == taskIndex) {
              item.viewVisible = !item.viewVisible;
            } else {
              item.viewVisible = false;
            }
          });
          this.$set(this.weekList[index][i], "taskShowList", infoList);
        } else if (showType == "list") {
          this.weekList.map((item, itemIndex) => {
            item.map((info, i) => {
              if (info.taskList && info.taskList.length > 0) {
                info.taskList.map((taskInfo) => {
                  taskInfo.viewVisible = false;
                });
              }
            });
          });
          this.$nextTick(() => {
            infoList = JSON.parse(
              JSON.stringify(this.weekList[index][i].taskList)
            );
            infoList.map((item, itemIndex) => {
              if (itemIndex == taskIndex) {
                item.viewVisible = !item.viewVisible;
              } else {
                item.viewVisible = false;
              }
            });
            this.$set(this.weekList[index][i], "taskList", infoList);
          });
        }
      } else if (type === "month") {
        if (showType == "show") {
          infoList = JSON.parse(
            JSON.stringify(this.monthList[index][i].taskShowList)
          );
          infoList.map((item, itemIndex) => {
            if (itemIndex == taskIndex) {
              item.viewVisible = !item.viewVisible;
            } else {
              item.viewVisible = false;
            }
          });
          this.$set(this.monthList[index][i], "taskShowList", infoList);
        } else if (showType == "list") {
          this.monthList.map((item, itemIndex) => {
            item.map((info, i) => {
              if (info.taskList && info.taskList.length > 0) {
                info.taskList.map((taskInfo) => {
                  taskInfo.viewVisible = false;
                });
              }
            });
          });
          this.$nextTick(() => {
            infoList = JSON.parse(
              JSON.stringify(this.monthList[index][i].taskList)
            );
            infoList.map((item, itemIndex) => {
              if (itemIndex == taskIndex) {
                item.viewVisible = !item.viewVisible;
              } else {
                item.viewVisible = false;
              }
            });
            this.$set(this.monthList[index][i], "taskList", infoList);
          });
        }
      }
    },
    //批量处理
    batchTask() {
      if (this.operateTableSelectData.length === 0) {
        this.$message.warning("请至少选择一个运营任务进行办理");
        return;
      }
      let ids = [];
      this.operateTableSelectData.map((item) => {
        ids.push(item.id + "");
      });
      this.$api
        .batchOperateTask({ ids })
        .then((res) => {
          if (res.data.code === "0") {
            this.$message({
              type: "success",
              message: "批量办理成功!",
              duration: 1500,
              onClose: () => {
                this.operateTableLoading = true;
                this.getOperateTaskList();
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //获取当月的第几周
    getMonthWeek(year, month, date) {
      let newDate = new Date(year, Number(month) - 1, date);
      let newWeek = newDate.getDay();
      let newDay = newDate.getDate();
      return Math.ceil((newDay + 6 - newWeek) / 7);
    },
    //根据当前年月第几周获取对应日期
    getWeekTime(year, month, weekday, nowDay) {
      let d = new Date();
      let newDate = "";
      // 该月第一天
      d.setFullYear(year, month - 1, 1);
      let w1 = d.getDay();
      let d1 = d.getDate();
      // if (w1 == 0) w1 = 7;
      // 该月天数
      d.setFullYear(year, month, 0);
      let wd = d.getDay();
      let dd = d.getDate();

      //上月最后
      d.setFullYear(year, month - 1, 0);
      let lastWd = d.getDay();
      let lastdd = d.getDate();

      //下月第一天
      d.setFullYear(
        month == 12 ? year + 1 : year,
        month == 12 ? 0 : month + 1,
        1
      );
      let nextWd = d.getDay();
      let nextdd = d.getDate();

      let monthWeek = parseInt((dd - (6 - w1) - (wd + 1)) / 7 + 2);
      console.log(monthWeek);
      // 这周周日
      if (w1 != 0 && weekday == 1 && nowDay >= w1) {
        newDate =
          year +
          "-" +
          (Number(month) < 9 ? "0" + month : month) +
          "-0" +
          (1 + (nowDay - w1));
      } else if (w1 != 0 && weekday == 1 && nowDay < w1) {
        newDate =
          (month - 1 == -1 ? year - 1 : year) +
          "-" +
          (month - 1 == -1
            ? 12
            : month - 1 == 0
            ? "01"
            : Number(month - 1) < 9
            ? "0" + (month - 1)
            : month - 1) +
          "-" +
          (lastdd - (w1 - nowDay));
      } else if (weekday == monthWeek && nowDay <= wd) {
        newDate =
          year +
          "-" +
          (Number(month) < 9 ? "0" + month : month) +
          "-" +
          (dd - (wd - nowDay));
      } else if (weekday == monthWeek && nowDay > wd) {
        newDate =
          (month == 12 ? year + 1 : year) +
          "-" +
          (month == 12
            ? "01"
            : Number(month + 1) < 9
            ? "0" + (month + 1)
            : month + 1) +
          ("-0" + (1 + (wd - nowDay)));
      } else {
        newDate =
          year +
          "-" +
          (Number(month) < 9 ? "0" + month : month) +
          "-" +
          (6 - w1 + (weekday - 1) * 7 < 9
            ? "0" + (6 - w1 + (weekday - 1) * 7)
            : 6 - w1 + (weekday - 1) * 7);
      }
      return newDate;
    },
    //新增运营代办
    addOperateTask() {
      this.$router.push("/addOperation");
    },
    //本周待办事件
    handleTaskToDoFun(item) {
      let nowDate = new Date();
      //2022-07-27 修改权限取值方式 dingyf
      let headquartersIndex = this.userInfo.orgAuth == "org:z" ? 0 : -1;
      let companyIndex = this.userInfo.companyAuth == "org:f" ? 0 : -1
      // let headquartersIndex = this.userInfo.authList.findIndex((authInfo) => {
      //   return authInfo == "org:z";
      // });
      // let companyIndex = this.userInfo.authList.findIndex((authInfo) => {
      //   return authInfo == "org:f";
      // });
      if (item.type === "1" && item.taskStatus != "4") {
        this.$router.push({
          path: "/agencyTaskScoreDetail",
          query: {
            id: item.id,
            status: item.taskStatus,
            jumpType: companyIndex != -1 ? "company" : "school",
          },
        });
      } else if (
        item.type == "2" &&
        (item.taskStatus == "0" || item.taskStatus == "5")
      ) {
        if (
          (item.taskStatus == "0" &&
            nowDate.getTime() <= new Date(item.endTime).getTime()) ||
          item.taskStatus == "5"
        ) {
          this.$router.push({
            path: "/upcomingTasksHandle",
            query: { id: item.id },
          });
        } else {
          this.$message.error("该任务已逾期，不可办理");
        }
      } else if (item.type == "2" && item.taskStatus == "1") {
        this.$router.push({
          path: "/upcomingTasksFeedback",
          query: { id: item.id },
        });
      }
    },
    //获取日历天数
    getAllMonthDay() {
      let monthList = [];
      let nowDay = new Date();
      let nowDatDate = nowDay.getDate();
      let lastMonthLastDay = new Date(
        this.calendarYear,
        this.calendarMonth - 1,
        0
      );
      let lastMonthLastDayDate = lastMonthLastDay.getDate();
      let firstDay = new Date(this.calendarYear, this.calendarMonth - 1, 1);
      let firstDayWeekDay = firstDay.getDay();
      let lastDay = new Date(this.calendarYear, this.calendarMonth, 0);
      let lastDayWeekDay = lastDay.getDay();
      let lastDayWeekDayDate = lastDay.getDate();
      let firstList = new Array(7);
      let lastList = new Array(7);
      let startDay = null;
      let endDay = null;
      if (firstDayWeekDay > 0) {
        for (let i = 0; i < firstDayWeekDay; i++) {
          let info = {
            day: lastMonthLastDayDate - firstDayWeekDay + 1 + i,
            date:
              lastMonthLastDay.getFullYear() +
              "-" +
              (lastMonthLastDay.getMonth() + 1 < 10
                ? "0" + (lastMonthLastDay.getMonth() + 1)
                : lastMonthLastDay.getMonth() + 1) +
              "-" +
              (lastMonthLastDayDate - firstDayWeekDay + 1 + i),
            isNow: false,
            isDisabled: true,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          firstList[i] = info;
        }
        for (let i = 0; i < 7 - firstDayWeekDay; i++) {
          let info = {
            day: i + 1,
            isNow:
              nowDay.getFullYear() == this.calendarYear &&
              nowDay.getMonth() + 1 == this.calendarMonth
                ? nowDatDate == i + 1
                  ? true
                  : false
                : false,
            date:
              this.calendarYear +
              "-" +
              (this.calendarMonth < 10
                ? "0" + this.calendarMonth
                : this.calendarMonth) +
              "-0" +
              (i + 1),
            isDisabled: false,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          if (i === 6 - firstDayWeekDay) {
            startDay = i + 2;
          }
          firstList[firstDayWeekDay + i] = info;
        }
      } else {
        startDay = 1;
        for (let i = 0; i < 7; i++) {
          let info = {
            day: lastMonthLastDayDate - 7 + 1 + i,
            date:
              lastMonthLastDay.getFullYear() +
              "-" +
              (lastMonthLastDay.getMonth() + 1 < 10
                ? "0" + (lastMonthLastDay.getMonth() + 1)
                : lastMonthLastDay.getMonth() + 1) +
              "-" +
              (lastMonthLastDayDate - 7 + 1 + i),
            isNow: false,
            isDisabled: true,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          firstList[i] = info;
        }
      }

      monthList.push(firstList);
      if (lastDayWeekDay >= 0 && lastDayWeekDay < 6) {
        for (let i = 0; i <= lastDayWeekDay; i++) {
          if (i === 0) {
            endDay = lastDayWeekDayDate - lastDayWeekDay - 1;
          }
          let info = {
            day: lastDayWeekDayDate - lastDayWeekDay + i,
            date:
              this.calendarYear +
              "-" +
              (this.calendarMonth < 10
                ? "0" + this.calendarMonth
                : this.calendarMonth) +
              "-" +
              (lastDayWeekDayDate - lastDayWeekDay + i),
            isNow:
              nowDay.getFullYear() == this.calendarYear &&
              nowDay.getMonth() + 1 == this.calendarMonth
                ? nowDatDate == lastDayWeekDayDate - lastDayWeekDay + i
                  ? true
                  : false
                : false,
            isDisabled: false,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          lastList[i] = info;
        }
        for (let i = 0; i < 6 - lastDayWeekDay; i++) {
          let info = {
            day: i + 1,
            date:
              (this.calendarMonth == 12
                ? this.calendarYear + 1
                : this.calendarYear) +
              "-" +
              (this.calendarMonth + 1 < 10
                ? "0" + (this.calendarMonth + 1)
                : this.calendarMonth + 1) +
              "-0" +
              (i + 1),
            isNow: false,
            isDisabled: true,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          lastList[lastDayWeekDay + (i + 1)] = info;
        }
      } else if (lastDayWeekDay == 6) {
        endDay = lastDayWeekDayDate;
        for (let i = 0; i < 7; i++) {
          let info = {
            day: i + 1,
            date:
              (this.calendarMonth == 12
                ? this.calendarYear + 1
                : this.calendarYear) +
              "-" +
              (this.calendarMonth + 1 < 10
                ? "0" + (this.calendarMonth + 1)
                : this.calendarMonth + 1) +
              "-0" +
              (i + 1),
            isNow: false,
            isDisabled: true,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          lastList[i] = info;
        }
      }
      for (let i = startDay; i <= endDay; i = i + 7) {
        let infoList = new Array(7);
        for (let j = 0; j < 7; j++) {
          let info = {
            day: i + j,
            date:
              this.calendarYear +
              "-" +
              (this.calendarMonth < 10
                ? "0" + this.calendarMonth
                : this.calendarMonth) +
              "-" +
              (i + j < 10 ? "0" + (i + j) : i + j),
            isNow:
              nowDay.getFullYear() == this.calendarYear &&
              nowDay.getMonth() + 1 == this.calendarMonth
                ? nowDatDate == i + j
                  ? true
                  : false
                : false,
            isShow: false,
            taskShowList: [],
            taskList: [],
          };
          infoList[j] = info;
        }
        monthList.push(infoList);
      }
      monthList.push(lastList);
      this.monthList = monthList;
      this.monthTdWidth = "";
      setTimeout(() => {
        this.monthTdWidth =
          (this.$refs.calendarInfo.clientWidth -
            (48 / 1920) * window.outerWidth -
            (210 / 1920) * window.outerWidth -
            (6 / 1920) * window.outerWidth) /
          7;
        this.$nextTick(() => {
          this.calendarAllHeight = this.$refs.calendarInfo.clientHeight;
        });
      }, 300);
      this.getCalendarTask("month");
    },
    //获取当前周的时刻表
    getDayTime() {
      this.weekList = [];
      let timeList = [
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ];
      let nowDay = new Date();
      let nowDatDate = nowDay.getDate();
      let nowDayWeek = this.getMonthWeek(
        nowDay.getFullYear(),
        nowDay.getMonth() + 1,
        nowDatDate
      );
      if (
        nowDay.getFullYear() == this.calendarYear &&
        nowDay.getMonth() + 1 == this.calendarMonth &&
        nowDayWeek == this.calendarWeek
      ) {
        this.weekShowDay = this.calendarWeekDay;
      } else {
        this.weekShowDay = "";
      }
      let resultList = [];
      for (let i = 0; i < 10; i++) {
        let infoList = new Array(8);
        for (let j = 0; j < 8; j++) {
          let info = {};
          if (j === 0) {
            info = {
              time: timeList[i],
              timeEnd: i === 9 ? timeList[10] : null,
            };
          } else {
            let day =
              this.calendarYear +
              "-" +
              this.calendarMonth +
              "-" +
              this.monthList[this.calendarWeek - 1][j - 1].day;
            info = {
              day: day,
              date: this.monthList[this.calendarWeek - 1][j - 1].date,
              timeStart: timeList[i],
              timeEnd: i === 9 ? timeList[10] : timeList[i + 1],
              isNow:
                nowDay.getFullYear() == this.calendarYear &&
                nowDay.getMonth() + 1 == this.calendarMonth &&
                nowDayWeek == this.calendarWeek
                  ? this.calendarWeekDay === j - 1
                    ? true
                    : false
                  : false,
              isShow: false,
              taskShowList: [],
              taskList: [],
            };
          }
          infoList[j] = info;
        }
        resultList.push(infoList);
      }
      this.weekList = resultList;
      console.log(resultList);
      setTimeout(() => {
        this.weekTdWidth =
          (this.$refs.calendarInfo.clientWidth -
            (48 / 1920) * window.outerWidth -
            (60 / 1920) * window.outerWidth -
            (200 / 1920) * window.outerWidth -
            (7 / 1920) * window.outerWidth) /
          7;
        this.$nextTick(() => {
          this.calendarAllHeight = this.$refs.calendarInfo.clientHeight;
        });
        this.getCalendarTask("week");
      }, 300);
    },
    //获取日历待办信息
    getCalendarTask(type) {
      let params = {};
      let date = null;
      let lastDate = null;
      let weekFirstDate = null;
      let weekEndDate = null;
      let year = null;
      let month = null;
      let nowDate = new Date();
      let nowDateTime = nowDate.getTime();
      let nowDateDay =
        nowDate.getFullYear() +
        "-" +
        (nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1) +
        "-" +
        nowDate.getDate() +
        " " +
        (nowDate.getHours() < 10
          ? "0" + nowDate.getHours()
          : nowDate.getHours()) +
        ":" +
        (nowDate.getMinutes() < 10
          ? "0" + nowDate.getMinutes()
          : nowDate.getMinutes()) +
        ":" +
        (nowDate.getSeconds() < 10
          ? "0" + nowDate.getSeconds()
          : nowDate.getSeconds());
      if (type === "month") {
        date = new Date(this.dateTime);
        year = date.getFullYear();
        month = date.getMonth();
        lastDate = new Date(year, month + 1, 0);
        params.flag = "month";
        params.startTime = year + "-" + (month + 1) + "-01 00:00:00";
        params.endTime =
          year + "-" + (month + 1) + "-" + lastDate.getDate() + " 23:59:59";
      } else if (type === "lastMonth") {
        date = new Date(this.dateTime);
        date.setMonth(date.getMonth() - 1);
        year = date.getFullYear();
        month = date.getMonth();
        lastDate = new Date(year, month, 0);
        params.flag = "month";
        params.startTime = year + "-" + (month + 1) + "-01 00:00:00";
        params.endTime =
          year + "-" + (month + 1) + "-" + lastDate.getDate() + " 23:59:59";
      } else if (type === "week" || type === "nowWeek") {
        date = new Date(this.dateTime);
        year = date.getFullYear();
        month = date.getMonth();
        lastDate = new Date(year, month, 0);
        weekFirstDate = new Date(this.dateTime);
        weekFirstDate.setDate(weekFirstDate.getDate() - date.getDay());
        weekEndDate = new Date(this.dateTime);
        weekEndDate.setDate(weekEndDate.getDate() + (6 - date.getDay()));
        params.flag = "week";
        if (weekFirstDate.getMonth() != month) {
          params.startTime = year + "-" + (month + 1) + "-01 00:00:00";
          params.endTime =
            year +
            "-" +
            (month + 1) +
            "-" +
            weekEndDate.getDate() +
            " 23:59:59";
        } else if (weekEndDate.getMonth() != month) {
          params.startTime =
            year +
            "-" +
            (month + 1) +
            "-" +
            weekFirstDate.getDate() +
            " 00:00:00";
          params.endTime =
            year + "-" + (month + 1) + "-" + lastDate.getDate() + " 23:59:59";
        } else {
          params.startTime =
            year +
            "-" +
            (month + 1) +
            "-" +
            weekFirstDate.getDate() +
            " 00:00:00";
          params.endTime =
            year +
            "-" +
            (month + 1) +
            "-" +
            weekEndDate.getDate() +
            " 23:59:59";
        }
      }
      this.$api
        .getViewTask(params)
        .then((res) => {
          if (res.data.code === "0") {
            let resultList = res.data.data;
            let list = [];
            if (type === "month") {
              this.monthList.map((item, index) => {
                item.map((dateInfo, i) => {
                  dateInfo.taskShowList = [];
                  dateInfo.taskList = [];
                  let resultTimeList = resultList.filter((resultInfo) => {
                    let startTime =
                      new Date(resultInfo.startTime).getTime() < nowDateTime
                        ? resultInfo.realStartTime
                          ? resultInfo.realStartTime
                          : resultInfo.taskStatus == "4"
                          ? resultInfo.doneTime
                          : new Date(resultInfo.endTime).getTime() <
                              nowDateTime && resultInfo.type == "2"
                          ? resultInfo.endTime
                          : nowDateDay
                        : resultInfo.realStartTime
                        ? resultInfo.realStartTime
                        : resultInfo.startTime;
                    if (startTime) {
                      return (
                        new Date(startTime.split(" ")[0]).getFullYear() ==
                          new Date(dateInfo.date).getFullYear() &&
                        new Date(startTime.split(" ")[0]).getMonth() ==
                          new Date(dateInfo.date).getMonth() &&
                        new Date(startTime.split(" ")[0]).getDate() ==
                          new Date(dateInfo.date).getDate()
                      );
                    } else {
                      return false;
                    }
                  });
                  if (resultTimeList && resultTimeList.length > 0) {
                    resultTimeList.map((resultTime) => {
                      let info = { ...resultTime };
                      info.week = index;
                      info.weekDay = i;
                      info.viewVisible = false;
                      if (
                        info.type == "1" &&
                        info.taskStatus != "4" &&
                        new Date(info.startTime).getTime() < nowDateTime
                      ) {
                        info.taskButtonText = "评分";
                      } else if (
                        info.type == "2" &&
                        (info.taskStatus == "0" || info.taskStatus == "5") &&
                        new Date(info.startTime).getTime() < nowDateTime
                      ) {
                        info.taskButtonText = "办理";
                      } else if (info.type == "2" && info.taskStatus == "1") {
                        info.taskButtonText = "逾期反馈";
                      } else if (
                        (info.type == "2" && info.taskStatus == "2") ||
                        info.taskStatus == "4" ||
                        ((info.taskStatus == "0" || info.taskStatus == "5") &&
                          new Date(info.startTime).getTime() > nowDateTime)
                      ) {
                        info.taskButtonText = "查看";
                      }
                      let startDate = null;
                      let endDate = null;
                      if (info.taskStatus == "1") {
                        startDate = Date.parse(info.endTime.replace(/-/g, '/'));
                        endDate = new Date();
                        info.surplusTime =
                          parseInt(
                            (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                          ) + "";
                      } else if (
                        (info.type == "1" && info.taskStatus == "0") ||
                        (info.type == "2" &&
                          (info.taskStatus == "0" || info.taskStatus == "5"))
                      ) {
                        startDate = new Date();
                        endDate = Date.parse(info.endTime.replace(/-/g, '/'));
                        info.surplusTime =
                          parseInt(
                            (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                          ) + "";
                      } else if (info.taskStatus == "4") {
                        startDate = new Date();
                        endDate = Date.parse(info.doneTime);
                        info.surplusTime =
                          parseInt(
                            (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                          ) + "";
                      }
                      if (new Date(info.startTime).getTime() > nowDateTime) {
                        info.noHandle = true;
                      } else {
                        info.noHandle = false;
                      }
                      info.monthIndex = index;
                      info.weekIndex = i;
                      info.taskInfoString = JSON.stringify(info);
                      dateInfo.taskList.push(info);
                      if (dateInfo.taskShowList.length < 3) {
                        dateInfo.taskShowList.push(info);
                      }
                    });
                  }
                });
              });
              this.$nextTick(() => {
                this.calendarAllHeight = this.$refs.calendarInfo.clientHeight;
              });
            } else if (type === "lastMonth") {
              if (resultList && resultList.length > 0) {
                this.isHaveTaskLastMonth = true;
              } else {
                this.isHaveTaskLastMonth = false;
              }
            } else if (type === "week") {
              this.weekList.map((item, index) => {
                item.map((dateInfo, i) => {
                  if (i != 0) {
                    dateInfo.taskShowList = [];
                    dateInfo.taskList = [];
                    let resultTimeList = resultList.filter((resultInfo) => {
                      let startTime =
                        new Date(resultInfo.startTime).getTime() < nowDateTime
                          ? resultInfo.realStartTime
                            ? resultInfo.realStartTime
                            : resultInfo.taskStatus == "4"
                            ? resultInfo.doneTime
                            : new Date(resultInfo.endTime).getTime() <
                                nowDateTime && resultInfo.type == "2"
                            ? resultInfo.endTime
                            : nowDateDay
                          : resultInfo.realStartTime
                          ? resultInfo.realStartTime
                          : resultInfo.startTime;
                      if (startTime) {
                        let startTimeList = startTime.split(" ");
                        let startTimeNumber =
                          Number(startTimeList[1].split(":")[0]) > 18
                            ? 17
                            : Number(startTimeList[1].split(":")[0]) < 8
                            ? 8
                            : Number(startTimeList[1].split(":")[0]);
                        return (
                          new Date(startTimeList[0]).getFullYear() ==
                            new Date(dateInfo.date).getFullYear() &&
                          new Date(startTimeList[0]).getMonth() ==
                            new Date(dateInfo.date).getMonth() &&
                          new Date(startTimeList[0]).getDate() ==
                            new Date(dateInfo.date).getDate() &&
                          Number(dateInfo.timeStart.split(":")[0]) ==
                            startTimeNumber
                        );
                      } else {
                        return false;
                      }
                    });
                    if (resultTimeList && resultTimeList.length > 0) {
                      resultTimeList.map((resultTimeInfo) => {
                        let info = { ...resultTimeInfo };
                        info.week = this.calendarWeek;
                        info.weekDay = i - 1;
                        info.viewVisible = false;
                        if (
                          info.type == "1" &&
                          info.taskStatus != "4" &&
                          new Date(info.startTime).getTime() < nowDateTime
                        ) {
                          info.taskButtonText = "评分";
                        } else if (
                          info.type == "2" &&
                          (info.taskStatus == "0" || info.taskStatus == "5") &&
                          new Date(info.startTime).getTime() < nowDateTime
                        ) {
                          info.taskButtonText = "办理";
                        } else if (info.type == "2" && info.taskStatus == "1") {
                          info.taskButtonText = "逾期反馈";
                        } else if (
                          (info.type == "2" && info.taskStatus == "2") ||
                          info.taskStatus == "4" ||
                          ((info.taskStatus == "0" || info.taskStatus == "5") &&
                            new Date(info.startTime).getTime() > nowDateTime)
                        ) {
                          info.taskButtonText = "查看";
                        }
                        let startDate = null;
                        let endDate = null;
                        if (info.taskStatus == "1") {
                          startDate = Date.parse(info.endTime.replace(/-/g, '/'));
                          endDate = nowDateTime;
                          info.surplusTime =
                            parseInt(
                              (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                            ) + "";
                        } else if (
                          (info.type == "1" && info.taskStatus == "0") ||
                          (info.type == "2" &&
                            (info.taskStatus == "0" || info.taskStatus == "5"))
                        ) {
                          startDate = nowDateTime;
                          endDate = Date.parse(info.endTime.replace(/-/g, '/'));
                          info.surplusTime =
                            parseInt(
                              (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                            ) + "";
                        } else if (info.taskStatus == "4") {
                          startDate = nowDateTime;
                          endDate = Date.parse(info.doneTime.replace(/-/g, '/'));
                          info.surplusTime =
                            parseInt(
                              (endDate - startDate) / (1 * 24 * 60 * 60 * 1000)
                            ) + "";
                        }
                        if (new Date(info.startTime).getTime() > nowDateTime) {
                          info.noHandle = true;
                        } else {
                          info.noHandle = false;
                        }
                        info.taskInfoString = JSON.stringify(info);
                        dateInfo.taskList.push(info);
                        if (dateInfo.taskShowList.length < 3) {
                          dateInfo.taskShowList.push(info);
                        }
                      });
                    }
                  }
                });
              });
              this.$nextTick(() => {
                this.calendarAllHeight = this.$refs.calendarInfo.clientHeight;
              });
            } else {
              // let headquartersIndex = this.userInfo.authList.findIndex(
              //   (authInfo) => {
              //     return authInfo == "org:z";
              //   }
              // );
              // let companyIndex = this.userInfo.authList.findIndex(
              //   (authInfo) => {
              //     return authInfo == "org:f";
              //   }
              // );
              resultList.map((item) => {
                if (item.taskStatus != "4" && item.taskStatus != "5") {
                  let info = { ...item };
                  info.noHandle = false;
                  if (info.taskStatus == "1") {
                    info.isDelay = true;
                  } else {
                    info.isDelay = false;
                    if (new Date(info.startTime).getTime() > nowDateTime) {
                      info.noHandle = true;
                    }
                  }
                  list.push(info);
                }
              });
              this.taskWeekList = list;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //处理弹框事件
    handleTaskPoperFun(taskInfo) {
      let nowDate = new Date();
      //2022-07-27 修改权限取值方式 dingyf
      let headquartersIndex = this.userInfo.orgAuth == "org:z" ? 0 : -1;
      let companyIndex = this.userInfo.companyAuth == "org:f" ? 0 : -1
      // let headquartersIndex1 = this.userInfo.authList.findIndex((authInfo) => {
      //   return authInfo == "org:z";
      // });
      // let companyIndex1 = this.userInfo.authList.findIndex((authInfo) => {
      //   return authInfo == "org:f";
      // });
      if (
        taskInfo.type == "1" &&
        taskInfo.taskStatus != "4" &&
        !taskInfo.noHandle
      ) {
        this.$router.push({
          path: "/agencyTaskScoreDetail",
          query: {
            id: taskInfo.id,
            status: taskInfo.taskStatus,
            jumpType: companyIndex != -1 ? "company" : "school",
          },
        });
      } else if (
        (taskInfo.type == "1" && taskInfo.taskStatus == "4") ||
        (taskInfo.type == "1" && taskInfo.noHandle)
      ) {
        this.$router.push({
          path: "/agencyTaskViewDetail",
          query: {
            id: taskInfo.id,
            jumpType:
              headquartersIndex != -1
                ? "headquarters"
                : headquartersIndex == -1 && companyIndex != -1
                ? "company"
                : "school",
          },
        });
      } else if (
        taskInfo.type == "2" &&
        (taskInfo.taskStatus == "0" || taskInfo.taskStatus == "5") &&
        !taskInfo.noHandle
      ) {
        if (
          (taskInfo.taskStatus == "0" &&
            nowDate.getTime() < new Date(taskInfo.endTime).getTime()) ||
          taskInfo.taskStatus == "5"
        ) {
          this.$router.push({
            path: "/upcomingTasksHandle",
            query: { id: taskInfo.id },
          });
        } else {
          this.$message.error("该任务已逾期，不可办理");
        }
      } else if (taskInfo.type == "2" && taskInfo.taskStatus == "1") {
        this.$router.push({
          path: "/upcomingTasksFeedback",
          query: { id: taskInfo.id },
        });
      } else if (
        (taskInfo.type == "2" &&
          (taskInfo.taskStatus == "4" || taskInfo.taskStatus == "2")) ||
        (taskInfo.type == "2" && taskInfo.noHandle)
      ) {
        this.$router.push({
          path: "/upcomingTasksDetail",
          query: { id: taskInfo.id },
        });
      }
    },
    //处理展开收起任务的事件
    handleShowAllTask(type, info, i, index) {
      let resultInfo = JSON.parse(JSON.stringify(info));
      resultInfo.taskList.map((item) => {
        item.viewVisible = false;
      });
      resultInfo.taskShowList.map((item) => {
        item.viewVisible = false;
      });
      if (type === "month") {
        resultInfo.isShow = !resultInfo.isShow;
        this.$set(this.monthList[index], i, resultInfo);
      } else if (type === "week") {
        resultInfo.isShow = !resultInfo.isShow;
        this.$set(this.weekList[index], i, resultInfo);
      }
      this.$nextTick(() => {
        this.calendarAllHeight = this.$refs.calendarInfo.clientHeight;
      });
    },
    //处理运营截止时间选择
    handleOperateDeadLineTime(val) {
      if (val && val.length > 0) {
        this.operateTaskForm.endTimeBegin = val[0];
        this.operateTaskForm.endTimeEnd = val[1];
      } else {
        this.operateTaskForm.endTimeBegin = "";
        this.operateTaskForm.endTimeEnd = "";
      }
    },
    //处理运营待办任务
    handleOperateTaskFun(type, row) {
      switch (type) {
        case "view":
          this.$router.push({
            path: "/upcomingTasksDetail",
            query: { id: row.id },
          });
          break;
        case "handle":
          this.$router.push({
            path: "/upcomingTasksHandle",
            query: { id: row.id },
          });
          break;
        case "overdueFeedback":
          this.$router.push({
            path: "/upcomingTasksFeedback",
            query: { id: row.id },
          });
          break;
        case "approve":
          this.$router.push({
            path: "/upcomingTasksApprove",
            query: { id: row.id },
          });
          break;
        default:
          break;
      }
    },
    // 运营查询
    operateSearchFun() {
      this.operatePagination.num = 1;
      this.operateTableLoading = true;
      this.getOperateTaskList();
    },
    // 运营重置
    operateResetFun() {
      this.operateTaskForm = {
        itemName: "",
        endTimeBegin: "",
        endTimeEnd: "",
      };
      this.operateDeadLineTime = [];
      this.operatePagination.num = 1;
      this.operateTableLoading = true;
      this.getOperateTaskList();
    },
    /** 运营分页 */
    handleOperateCurrentChange(val) {
      this.operatePagination.num = val;
      this.operateTableLoading = true;
      this.getOperateTaskList();
    },
    handleOperateSizeChange(val) {
      this.operatePagination.size = val;
      this.operateTableLoading = true;
      this.getOperateTaskList();
    },
    //获取运营选中值
    handleOperateSelectionChange(val) {
      this.operateTableSelectData = val;
    },
    //处理是否可以选择
    handelOperateSelectable(row, index) {
      console.log(row);
      if (
        (row.taskStatus.value === "0" &&
          row.isMust.value === "0" &&
          row.timeBtn >= 0 &&
          row.isCustom &&
          row.isCustom.value === "0") ||
        (row.isCustom &&
          row.isCustom.value === "1" &&
          row.isMust.value === "0" &&
          row.taskStatus.value !== "4")
      ) {
        return true;
      } else {
        return false;
      }
    },
    //获取运营待办list
    getOperateTaskList() {
      let params = {};
      for (let key in this.operateTaskForm) {
        if (this.operateTaskForm[key]) {
          params[key] = this.operateTaskForm[key];
        }
      }
      params.flag = "homepage";
      params.current = this.operatePagination.num;
      params.size = this.operatePagination.size;
      this.$api
        .getHomeOperateList(params)
        .then((res) => {
          if (res.data.code === "0") {
            this.operateTableLoading = false;
            res.data.data.records.map((item) => {
              item.startTime = item.startTime
                ? item.startTime.split(" ")[0]
                : "";
              item.endTime = item.endTime ? item.endTime.split(" ")[0] : "";
            });
            this.operateTableData = res.data.data.records;
            this.operatePagination.total = res.data.data.total;
            if (res.data.data.total > 0) {
              this.operatePageFlag = true;
            } else {
              this.operatePageFlag = false;
            }
          } else {
            this.$message.error(res.data.msg);
            this.operateTableLoading = false;
          }
        })
        .catch(() => {
          this.operateTableLoading = false;
        });
    },
    //处理督导截止时间选择
    handleSuperviseDeadLineTime(val) {
      if (val && val.length > 0) {
        this.superviseTaskForm.beginTime = val[0];
        this.superviseTaskForm.endTime = val[1];
      } else {
        this.superviseTaskForm.beginTime = "";
        this.superviseTaskForm.endTime = "";
      }
    },
    //处理督导待办任务
    handleSuperviseTaskFun(type, row) {
      switch (type) {
        case "view":
          this.$router.push({
            path: "/agencyTaskViewDetail",
            query: {
              id: row.orderId,
              jumpType:
                row.status === "2" || row.status === "7" ? "company" : "school",
            },
          });
          break;
        case "score":
          this.$router.push({
            path: "/agencyTaskScoreDetail",
            query: {
              id: row.orderId,
              status: row.status,
              jumpType:
                row.status === "2" || row.status === "7" ? "company" : "school",
            },
          });
          break;
        case "supervise":
          break;
        default:
          break;
      }
    },
    // 督导查询
    superviseSearchFun() {
      this.superviseTaskForm.num = 1;
      this.superviseTableLoading = true;
      this.getSuperviseTaskList();
    },
    // 督导重置
    superviseResetFun() {
      this.superviseTaskForm = {
        supervisionTopic: "",
        beginTime: "",
        endTime: "",
      };
      this.superviseDeadLineTime = [];
      this.supervisePagination.num = 1;
      this.superviseTableLoading = true;
      this.getSuperviseTaskList();
    },
    /** 督导分页 */
    handleSuperviseCurrentChange(val) {
      this.supervisePagination.num = val;
      this.superviseTableLoading = true;
      this.getSuperviseTaskList();
    },
    handleSuperviseSizeChange(val) {
      this.supervisePagination.size = val;
      this.superviseTableLoading = true;
      this.getSuperviseTaskList();
    },
    //获取督导待办list
    getSuperviseTaskList() {
      let params = {};
      for (let key in this.superviseTaskForm) {
        if (this.superviseTaskForm[key]) {
          params[key] = this.superviseTaskForm[key];
        }
      }
      params.current = this.supervisePagination.num;
      params.size = this.supervisePagination.size;
      this.$api
        .getHomeSuperviseList(params)
        .then((res) => {
          if (res.data.code === "0") {
            this.superviseTableLoading = false;
            res.data.data.records.map((item) => {
              let supervisionMethodName = "";
              let supervisionMethodIndex = this.superviseTypeOptions.findIndex(
                (superviseTypeInfo) => {
                  return superviseTypeInfo.value == item.supervisionMethod;
                }
              );
              if (supervisionMethodIndex != -1) {
                supervisionMethodName =
                  this.superviseTypeOptions[supervisionMethodIndex].name;
              }
              item.supervisionMethodName = supervisionMethodName;

              let statusName = "";
              let statusIndex = this.flowStatusOptions.findIndex(
                (statusInfo) => {
                  return statusInfo.value == item.status;
                }
              );
              if (statusIndex != -1) {
                statusName = this.flowStatusOptions[statusIndex].name;
              }
              item.statusName = statusName;
            });
            this.superviseTableData = res.data.data.records;
            this.supervisePagination.total = res.data.data.total;
            if (res.data.data.total > 0) {
              this.supervisePageFlag = true;
            } else {
              this.supervisePageFlag = false;
            }
          } else {
            this.$message.error(res.data.msg);
            this.superviseTableLoading = false;
          }
        })
        .catch(() => {
          this.superviseTableLoading = false;
        });
    },
    //处理目标截止时间选择
    handleTargetDeadLineTime(val) {
      if (val && val.length > 0) {
        this.targetTaskForm.searchStartTime = val[0];
        this.targetTaskForm.searchEndTime = val[1];
      } else {
        this.targetTaskForm.searchStartTime = "";
        this.targetTaskForm.searchEndTime = "";
      }
    },
    //处理目标待办任务
    handleTargetTaskFun(type, row, targetType) {
      switch (type) {
        case "updateDonePer":
          this.$router.push({
            path: "/uploadProgress",
            query: { lookId: row.id, type: targetType },
          });
          break;
        default:
          break;
      }
    },
    // m目标查询
    targetSearchFun() {
      this.targetTaskForm.num = 1;
      this.targetTableLoading = true;
      this.getTargetTaskList();
    },
    // 目标重置
    targetResetFun() {
      this.targetTaskForm = {
        targetName: "",
        searchStartTime: "",
        searchEndTime: "",
      };
      this.targetDeadLineTime = [];
      this.targetPagination.num = 1;
      this.targetTableLoading = true;
      this.getTargetTaskList();
    },
    /** 目标分页 */
    handleTargetCurrentChange(val) {
      this.targetPagination.num = val;
      this.targetTableLoading = true;
      this.getTargetTaskList();
    },
    handleTargetSizeChange(val) {
      this.targetPagination.size = val;
      this.targetTableLoading = true;
      this.getTargetTaskList();
    },
    //获取目标待办list
    getTargetTaskList() {
      let params = {};
      for (let key in this.targetTaskForm) {
        if (this.targetTaskForm[key]) {
          params[key] = this.targetTaskForm[key];
        }
      }
      params.flag = "homepage";
      params.current = this.targetPagination.num;
      params.size = this.targetPagination.size;
      this.$api
        .getHomeTargetList(params)
        .then((res) => {
          if (res.data.code === "0") {
            this.targetTableLoading = false;
            this.targetTableData = res.data.data.records;
            this.targetPagination.total = res.data.data.total;
            if (res.data.data.total > 0) {
              this.targetPageFlag = true;
            } else {
              this.targetPageFlag = false;
            }
          } else {
            this.$message.error(res.data.msg);
            this.targetTableLoading = false;
          }
        })
        .catch(() => {
          this.targetTableLoading = false;
        });
    },
  },
  beforeDestroy() {
    let that = this;
    document.removeEventListener("click", function (e) {
      console.log(e);
      that.monthList.map((item, index) => {
        item.map((info, i) => {
          if (info.taskList && info.taskList.length > 0) {
            info.taskList.map((taskInfo) => {
              taskInfo.viewVisible = false;
            });
          }
        });
      });
      that.weekList.map((item, index) => {
        item.map((info, i) => {
          if (info.taskList && info.taskList.length > 0) {
            info.taskList.map((taskInfo) => {
              taskInfo.viewVisible = false;
            });
          }
        });
      });
    });
  },
};
</script>
<style lang="scss" scoped>
.home-contain {
  background-color: #f4f4fc;
  width: calc(100% - 64px);
  // height: calc(100% - 80px);
  padding: 34px 30px 30px 30px;
  .header-title {
    font-size: 22px;
    font-weight: bold;
    color: #3d3e4f;
    margin-bottom: 30px;
  }
  .calendar-taskweek-info {
    width: 100%;
    min-height: 620px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .calendar-info {
      width: calc(100% - 528px);
      min-height: 568px;
      padding: 28px 24px 24px 24px;
      border-radius: 8px;
      background-color: #ffffff;
      position: relative;
      .calendar-body {
        width: 100%;
        .calendar-body-header {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 6px;
          position: relative;
          .calendar-time {
            display: flex;
            align-items: center;
            margin: 0 46px;
            font-size: 24px;
            font-weight: bold;
            color: #010334;
            cursor: pointer;
            img {
              width: 10px;
              height: 5px;
              margin-left: 5px;
              &.changeIcon {
                transform: rotate(180deg);
              }
            }
          }
          .icon-item {
            width: 10px;
            height: 14px;
            cursor: pointer;
          }
          .calendar-date-choose {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            z-index: -1;
            opacity: 0;
            .el-input--prefix .el-input__inner {
            }
          }
        }
        // .week-move-outside{
        //   width: 100%;
        //   margin-top: 25px;
        // }
        // .month-move-outside{
        //   width: 100%;
        //   table-layout: fixed;
        //   margin-top: 25px;
        // }
        .calendar-body-info {
          width: 100%;
          margin-top: 25px;
          thead {
            padding: 0;
            width: 100%;
            border: none;
            background-color: #f1f4f9;
            border-radius: 12px 12px 0px 0px;
            &.week {
              background-color: transparent;
              border-radius: 0px 12px 0px 0px;
              tr {
                border-radius: 0px 12px 0px 0px;
                th {
                  background-color: #f1f4f9;
                  &.time {
                    width: calc(calc(100% - 60px) / 7);
                  }
                  &:first-child {
                    width: 60px;
                    background-color: transparent;
                    border-top-left-radius: 0;
                  }
                  &:nth-child(2) {
                    border-top-left-radius: 12px;
                  }
                }
              }
            }
            tr {
              width: 100%;
              padding: 0;
              border: none;
              border-radius: 12px 12px 0px 0px;
              th {
                padding: 0;
                width: calc(100% / 7);
                height: 48px;
                text-align: center;
                line-height: 48px;
                font-size: 14px;
                font-weight: 400;
                color: #5e6d9b;
                &.time {
                  width: calc(100% / 8);
                }
                .now {
                  width: 26px;
                  height: 26px;
                  line-height: 26px;
                  text-align: center;
                  display: inline-block;
                  background-color: #003685;
                  box-shadow: 0px 9px 9px rgba(0, 54, 133, 0.2);
                  border-radius: 50%;
                  color: #ffffff;
                }
                &:first-child {
                  border-top-left-radius: 12px;
                }
                &:last-child {
                  border-top-right-radius: 12px;
                }
              }
            }
          }
          tbody {
            width: 100%;
            padding: 0;
            border: none;
            .month-tr {
              width: 100%;
              border: none;
              padding: 0;
              .month-td {
                border-right: 1px solid rgba(162, 166, 175, 0.3); //#A2A6AF
                border-bottom: 1px solid rgba(162, 166, 175, 0.3); //#A2A6AF
                height: 75px;
                box-sizing: border-box;
                width: calc(100% / 7);
                .day-show-info {
                  width: calc(100% - 16px);
                  margin: 8px 6px;
                  height: 26px;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  .day-show {
                    height: 26px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #010334;
                    &.gray {
                      color: #a8a6b9;
                    }
                    &.active {
                      width: 26px;
                      color: #ffffff;
                      line-height: 26px;
                      text-align: center;
                      background-color: #003685;
                      box-shadow: 0px 9px 9px rgba(0, 54, 133, 0.2);
                      border-radius: 50%;
                    }
                  }
                }
                .task-list {
                  width: calc(100% - 30px);
                  padding: 0 15px;
                  position: relative;
                  .taskItem {
                    display: inline-block;
                    padding: 12px 0;
                    margin-right: 10px;
                    &.noMr {
                      margin-right: 5px;
                    }
                    .task-img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .task-all-list {
                    height: 75px;
                    overflow: hidden;
                    overflow-y: auto;
                    box-sizing: border-box;
                    .task-all-list-inside {
                      width: 100%;
                      height: 100%;
                      position: relative;
                      /deep/.task-show-button {
                        &.moveShow {
                          position: absolute;
                          right: 0;
                          top: 0;
                          background-color: #ffffff;
                          width: 35px;
                        }
                        &.moveBlueShow {
                          position: absolute;
                          right: 0;
                          top: 0;
                          background-color: #eaf1fc;
                          width: 35px;
                        }
                      }
                    }
                  }
                }
                &.disabledBg {
                  background-image: url("../../assets/img/calendar-disabled-bg.png");
                  background-size: 100% 100%;
                }
                &.active {
                  background-color: #eaf1fc;
                }
                &.noRightBorder {
                  border-right: none;
                }
                &.noBorder {
                  border-bottom: none;
                }
                &.longHeight {
                  height: 90px;
                }
              }
            }
            .week-tr {
              width: 100%;
              border: none;
              padding: 0;
              .week-td {
                width: calc(calc(100% - 60px) / 7);
                height: 45px;
                border-right: 1px solid rgba(162, 166, 175, 0.3); //#A2A6AF
                border-bottom: 1px solid rgba(162, 166, 175, 0.3); //#A2A6AF
                box-sizing: border-box;
                vertical-align: top;
                &:first-child {
                  width: 60px;
                }
                &.active {
                  background-color: #eaf1fc;
                }
                &.noRightBorder {
                  border-right: none;
                }
                &.noBorder {
                  border-bottom: none;
                }
                .time-start {
                  transform: translateY(-9px);
                  text-align: right;
                  padding-right: 10px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #8c94a7;
                }
                .time-end {
                  transform: translateY(13px);
                  text-align: right;
                  padding-right: 10px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #8c94a7;
                }
                .task-list {
                  width: calc(100% - 30px);
                  padding: 5px 15px;
                  position: relative;
                  .taskItem {
                    width: 20px;
                    height: 20px;
                    padding: 12px 0;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 8px;
                    &.noMr {
                      margin-right: 5px;
                    }
                    &.weekPadding {
                      padding: 0;
                    }
                    .task-img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .task-all-list {
                    height: 42px;
                    overflow: hidden;
                    overflow-y: auto;
                    box-sizing: border-box;
                    .task-all-list-inside {
                      width: 100%;
                      height: 100%;
                      position: relative;
                      /deep/.task-show-button {
                        &.moveShow {
                          position: absolute;
                          right: 0;
                          top: 0;
                          background-color: #ffffff;
                          width: 35px;
                        }
                        &.moveBlueShow {
                          position: absolute;
                          right: 0;
                          top: 0;
                          background-color: #eaf1fc;
                          width: 35px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .calendar-tabs {
        position: absolute;
        top: 28px;
        right: 24px;
        display: flex;
        align-items: center;
        .tab-item {
          width: 46px;
          height: 38px;
          text-align: center;
          line-height: 38px;
          font-size: 14px;
          font-weight: 400;
          color: #8c94a7;
          border-radius: 8px;
          cursor: pointer;
          &.active {
            background-color: #eaf1fc;
            color: #003685;
          }
        }
      }
    }
    .taskweek-info {
      width: 450px;
      padding: 0 40px 36px 40px;
      height: 620px;
      border-radius: 8px;
      background-color: #ffffff;
      box-sizing: border-box;
      .warning-tip {
        width: 350px;
        height: 30px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 30px;
        border-radius: 30px;
        background-color: #ffe8eb;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 20px;
          height: 18px;
          margin-right: 6px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #c6000b;
        }
      }
      .taskweek-header {
        width: 100%;
        height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .taskweek-text {
          font-size: 14px;
          font-weight: 400;
          color: #9290b1;
        }
      }
      .taskweek-footer-btn {
        background: #003685;
        box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 40px;
      }
      .taskweek-list-outside {
        width: calc(100% - 25px);
        padding-right: 25px;
        height: calc(100% - 156px);
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &.haveTip {
          height: calc(100% - 216px);
        }
        .taskweek-list {
          width: 100%;
          height: auto;
          .list-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 28px;
            &:last-child {
              margin-bottom: 0;
            }
            .item-info {
              width: calc(100% - 60px);
              display: flex;
              align-items: center;
              .icon {
                width: 50px;
                height: 50px;
                background-color: #eaf1fc;
                border-radius: 12px;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.red {
                  background-color: #ffe8eb;
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
              .text {
                width: calc(100% - 66px);
                height: 18px;
                font-size: 16px;
                font-weight: 400;
                color: #3d3e4f;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &.gray {
                  color: #a8a6b9;
                }
              }
            }
          }
        }
        .taskweek-list-empty {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 211px;
            height: 131px;
            margin-bottom: 32px;
          }
          .taskweek-list-empty-text {
            font-size: 18px;
            font-weight: 400;
            color: #a8a6b9;
          }
        }
      }
    }
  }
  .all-task-info {
    width: calc(100% - 60px);
    height: 590px;
    padding: 30px;
    margin-top: 30px;
    border-radius: 8px;
    background-color: #ffffff;
    .task-header {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .task-tabs {
      width: 100%;
      height: 40px;
      margin-bottom: 34px;
      position: relative;
      .tab-item {
        height: 40px;
        line-height: 40px;
        color: #a8a6b9;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        margin-right: 34px;
        cursor: pointer;
        &.active {
          color: #3d3e4f;
        }
      }
      .tab-line {
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 20px;
        border-radius: 20px;
        background-color: #e7c69c;
        transition: transform 0.3s;
      }
    }
    .all-task-list-outside {
      width: 100%;
      height: calc(100% - 114px);
      overflow: hidden;
      .all-task-list {
        width: 300%;
        height: 100%;
        transition: transform 0.3s;
        .item-list {
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          .tableList {
            width: 100%;
            height: calc(100% - 62px);
          }
        }
      }
    }
  }
  .mt {
    margin-top: 20px;
  }
  .task-title {
    font-size: 18px;
    font-weight: 400;
    color: #3d3e4f;
  }
  .sheet-btn {
    background: #003685;
    box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    width: auto;
  }
}
.task-poper-body {
  width: calc(100% - 32px);
  padding: 0 16px;
  .poper-body-header {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 14px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }
    span {
      font-size: 16px;
      font-weight: 400;
      color: #3d3e4f;
    }
  }
  .poper-body-content {
    width: 100%;
    .item {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      color: #9290b1;
      margin-bottom: 8px;
    }
  }
  .poper-body-footer {
    width: 100%;
    text-align: center;
    padding-top: 16px;
  }
}
.delay {
  color: #c6000b;
}
.finish {
  color: #00ab9f;
}
</style>
